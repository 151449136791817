import { StyledInputText } from "../../../../components/Utils/StyledInput/StyledInput";
import { CategoryType, SubCategoryType } from "../../../../types/Category";
import "./CategoryFilter.scss";

const CategoryFilter: React.FC<{
  categories: CategoryType[];
  subCategories: SubCategoryType[];
  selectedCategory: CategoryType | undefined;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<CategoryType | undefined>
  >;
  selectedSubCategory: SubCategoryType | undefined;
  setSelectedSubCategory: React.Dispatch<
    React.SetStateAction<SubCategoryType | undefined>
  >;
  categorySearch: string;
  setCategorySearch: React.Dispatch<React.SetStateAction<string>>;

  subCategorySearch: string;
  setSubCategorySearch: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  categories,
  subCategories,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  categorySearch,
  setCategorySearch,
  subCategorySearch,
  setSubCategorySearch,
}) => {
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase()),
  );

  const filteredSubCategories = subCategories.filter((subCategory) =>
    subCategory.name.toLowerCase().includes(subCategorySearch.toLowerCase()),
  );

  return (
    <div className="categoryFilter">
      <StyledInputText
        label="Catégorie"
        value={categorySearch}
        setValue={setCategorySearch}
        className="categorySearchInput"
      />
      <div className="categoryList">
        {filteredCategories.map((category) => (
          <div key={category.category_id} className="categoryItem">
            <input
              type="checkbox"
              checked={selectedCategory?.category_id === category.category_id}
              onChange={() =>
                setSelectedCategory(
                  selectedCategory?.category_id === category.category_id
                    ? undefined
                    : category,
                )
              }
            />
            <label>{category.name}</label>
          </div>
        ))}
      </div>
      <StyledInputText
        label="Sous Catégorie"
        value={subCategorySearch}
        setValue={setSubCategorySearch}
        className="categorySearchInput"
      />
      <div className="categoryList">
        {filteredSubCategories.map((subCategory) => (
          <div key={subCategory.sub_category_id} className="categoryItem">
            <input
              type="checkbox"
              checked={
                selectedSubCategory?.sub_category_id ===
                subCategory.sub_category_id
              }
              onChange={() =>
                setSelectedSubCategory(
                  selectedSubCategory?.sub_category_id ===
                    subCategory.sub_category_id
                    ? undefined
                    : subCategory,
                )
              }
            />
            <label>{subCategory.name}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryFilter;
