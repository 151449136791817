import "./ModalContent.scss";

import { FaUndo } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";

import Button from "../../../../../components/Utils/Button/Button";
import StyledDropdown from "../../../../../components/Utils/StyledDropdown/StyledDropdown";
import {
  StyledInputFile,
  StyledInputNumber,
  StyledInputText,
} from "../../../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../../../stores/LoadingStore";
import { CategoryType, SubCategoryType } from "../../../../../types/Category";
import ProductType from "../../../../../types/Product";

interface ModalContentProps {
  setUpdatedProduct: React.Dispatch<React.SetStateAction<ProductType>>;
  updatedProduct: ProductType;
  categories: CategoryType[];
  subCategories: SubCategoryType[];
}
const ModalContent = ({
  updatedProduct,
  setUpdatedProduct,
  categories,
  subCategories,
}: ModalContentProps) => {
  const handleChange =
    (key: keyof ProductType) => (value: string | number | File | null) => {
      setUpdatedProduct((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };
  const handleCategoryChange = (value: CategoryType | undefined) => {
    if (!value) return;
    setUpdatedProduct((prevState) => ({
      ...prevState,
      category_id: value.category_id,
    }));
  };

  return (
    <div className="modalContent">
      <StyledInputText
        label="Nom*"
        value={updatedProduct.name}
        setValue={handleChange("name")}
      />
      <StyledInputNumber
        label="Prix*"
        value={updatedProduct.price}
        setValue={handleChange("price")}
      />

      <StyledDropdown
        label="Catégorie*"
        value={categories.find(
          (category) => category.category_id === updatedProduct.category_id,
        )}
        itemList={categories}
        setValue={handleCategoryChange}
        itemLabelKey="name"
        itemIdKey="category_id"
      />
      <StyledDropdown
        label="Sous-catégorie"
        value={subCategories.find(
          (category) =>
            category.sub_category_id === updatedProduct.subCategory_id,
        )}
        itemList={subCategories}
        setValue={(value) => {
          if (!value) return;
          setUpdatedProduct((prevState) => ({
            ...prevState,
            subCategory_id: value.sub_category_id,
          }));
        }}
        itemLabelKey="name"
        itemIdKey="sub_category_id"
      />
      <StyledInputNumber
        label="Stock*"
        value={updatedProduct.stock ? updatedProduct.stock.toString() : "0"}
        setValue={handleChange("stock")}
      />
      <StyledInputFile
        label="Image"
        value={
          updatedProduct.image instanceof File ? updatedProduct.image : null
        }
        setValue={(file: File | null) => handleChange("image")(file)}
      />
    </div>
  );
};

interface ModalFooterButtonProps {
  onClick?: () => void;
  onClickWithParam?: (param: number) => void;
  paramValue?: number;
  addProduct?: boolean;
  onDelete?: () => void;
  handleReset?: () => void;
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  onClick,
  onClickWithParam,
  paramValue,
  addProduct,
  onDelete,
  handleReset,
}) => {
  const { loadingStates } = useLoadingStore();

  return (
    <>
      {onClick && (
        <Button
          className="modalFooterButton"
          onClick={onClick}
          label={addProduct ? "Ajouter" : "Modifier"}
          isLoading={loadingStates.addProduct}
        />
      )}
      {onClickWithParam && paramValue !== undefined && (
        <Button
          className="modalFooterButton"
          onClick={() => onClickWithParam(paramValue)}
          label={addProduct ? "Ajouter" : "Modifier"}
          isLoading={loadingStates.updateProduct}
        />
      )}
      {handleReset && (
        <Button
          onClick={handleReset}
          className="squaredButton"
          icon={<FaUndo />}
        />
      )}
      {!addProduct && (
        <Button
          className="modalFooterButton"
          onClick={onDelete}
          icon={<FiTrash2 />}
          isLoading={loadingStates.deleteProduct}
        />
      )}
    </>
  );
};

export default ModalContent;
export { ModalFooterButton };
