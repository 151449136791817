import "./Calendar.scss";
import "./react-datepicker.scss";

import { fr } from "date-fns/locale/fr";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

registerLocale("fr", fr);

interface CalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: (update: [Date, Date]) => void;
}

const Calendar: React.FC<CalendarProps> = ({
  startDate,
  endDate,
  setDateRange,
}) => {
  // Local state to manage the incomplete date range selection
  const [selection, setSelection] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);

  // Update local selection state if the props change
  useEffect(() => {
    setSelection([startDate, endDate]);
  }, [startDate, endDate]);

  // Handle changes from the DatePicker
  const handleDateChange = (update: [Date | null, Date | null]) => {
    // Update the local state with the current selection
    setSelection(update);

    // Destructure start and end dates from the update
    const [start, end] = update;

    // If both dates are selected, call the parent's setDateRange function
    if (start && end) {
      setDateRange([start, end]);
    }
  };

  return (
    <div className="calendarDateSelect">
      <DatePicker
        selectsRange
        startDate={selection[0]}
        endDate={selection[1]}
        onChange={handleDateChange}
        isClearable
        locale="fr"
      />
    </div>
  );
};

export default Calendar;
