import "./InvoiceList.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiDownload } from "react-icons/bi";
import { FiCheck } from "react-icons/fi";

import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import StyledSwitch from "../../../components/Utils/StyledSwitch/StyledSwitch";
import useLoadingStore from "../../../stores/LoadingStore";
import InvoiceType from "../../../types/Invoice";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState<InvoiceType[]>();
  const [search, setSearch] = useState("");
  const { loadingStates, setLoading } = useLoadingStore();
  const [showValidated, setShowValidated] = useState(
    localStorage.getItem("showValidated") === "true",
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading("fetchInvoices", true);
      const invoiceData = await appFetch(`/api/invoice/getInvoiceList`);
      if (invoiceData) {
        setInvoices(invoiceData.invoices);
      }
      setLoading("fetchInvoices", false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Save the showValidated state to localStorage
    localStorage.setItem("showValidated", showValidated.toString());
  }, [showValidated]);

  const filteredInvoices = invoices?.filter((invoice) => {
    const matchesSearch =
      invoice.client?.client_name
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      invoice.invoice_id.toString().includes(search.toLowerCase());

    const matchesValidatedFilter =
      showValidated || invoice.status !== "Validated";

    return matchesSearch && matchesValidatedFilter;
  });

  const downloadPDF = async (invoice: InvoiceType) => {
    setLoading(`downloadInvoice${invoice.invoice_id}`, true);
    const response = await appFetch(
      `/api/invoice/getInvoicePdf?invoice_id=${invoice.invoice_id}`,
    );

    if (response && response instanceof Blob) {
      const filename = `FACTURE-${invoice.formatted_invoice_id}.pdf`;

      const blobURL = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (response && response.error) {
      toast.error(response.message);
    }
    setLoading(`downloadInvoice${invoice.invoice_id}`, false);
  };

  const deleteInvoice = async (invoiceId: number) => {
    console.log(invoiceId);

    setLoading(`delete${invoiceId}`, true);
    const response = await appFetch(
      `/api/invoice/deleteInvoice?invoice_id=${invoiceId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      const invoiceData = await appFetch("/api/invoice/getInvoiceList");
      if (invoiceData) {
        setInvoices(invoiceData.invoices);
      }
    }
    setLoading(`delete${invoiceId}`, false);
  };

  const validateInvoice = async (invoiceId: number) => {
    setLoading(`validateInvoice${invoiceId}`, true);
    const response = await appFetch(
      `/api/invoice/validateInvoice?invoice_id=${invoiceId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);
      const invoiceData = await appFetch("/api/invoice/getInvoiceList");
      if (invoiceData) {
        setInvoices(invoiceData.invoices);
      }
    }
    setLoading(`validateInvoice${invoiceId}`, false);
  };

  const collumns = [
    {
      title: "ID",
      dataIndex: "formatted_invoice_id",
      render: (_formatted_invoice_id: string, invoice: InvoiceType) => (
        <span>{invoice.formatted_invoice_id}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "quote_id",
      render: (_quote_id: number, invoice: InvoiceType) =>
        invoice.quote_id ? "Location" : "Vente",
    },
    {
      title: "Client",
      dataIndex: "client.client_name",
      render: (_client_name: string, invoice: InvoiceType) =>
        invoice.client?.client_name ?? "",
    },
    {
      title: "Date",
      dataIndex: "issue_date",
      render: (_issue_date: Date, invoice: InvoiceType) =>
        new Date(invoice.issue_date).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
    },
    {
      title: "Réduction (€)",
      dataIndex: "quote.discount",
      render: (_discount: number, invoice: InvoiceType) => (
        <span>{invoice.quote?.discount} €</span>
      ),
    },
    {
      title: "Prix total",
      dataIndex: "total_amount",
      render: (_total_amount: number, invoice: InvoiceType) => (
        <span>{invoice.total_amount} €</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_status: string, invoice: InvoiceType) => (
        <span>{invoice.status}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, invoice: InvoiceType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="downloadButton"
            onClick={() => downloadPDF(invoice)}
            icon={<BiDownload />}
            isLoading={loadingStates[`downloadInvoice${invoice.invoice_id}`]}
            style="square"
          />

          {invoice.status === "Pending" && (
            <Button
              className="validateButton"
              onClick={() => validateInvoice(invoice.invoice_id)}
              icon={<FiCheck />}
              isLoading={loadingStates[`validateInvoice${invoice.invoice_id}`]}
              style="square"
            />
          )}

          {invoice.status !== "Validated" && (
            <DeleteButton
              onClick={() => deleteInvoice(invoice.invoice_id)}
              isLoading={loadingStates[`delete${invoice.invoice_id}`]}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={`invoiceListPage`}>
      <div className="header">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <StyledSwitch
          checked={showValidated}
          onChange={() => setShowValidated(!showValidated)}
          label="Afficher les factures validées"
        />
      </div>
      <div className="tableContainer">
        {loadingStates.fetchInvoices ? (
          <Spinner />
        ) : (
          <DynamicTable
            data={filteredInvoices ?? []}
            columns={collumns}
            onClick={(record) => console.log(record)}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceList;
