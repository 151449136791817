import "./ModalContent.scss";

import Button from "../../../../components/Utils/Button/Button";
import { StyledInputText } from "../../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../../stores/LoadingStore";
import { CategoryType, SubCategoryType } from "../../../../types/Category";

interface ModalContentProps {
  setupdatedCategory: React.Dispatch<
    React.SetStateAction<CategoryType | SubCategoryType>
  >;
  updatedCategory: CategoryType | SubCategoryType;
}

const ModalContent = ({
  updatedCategory,
  setupdatedCategory,
}: ModalContentProps) => {
  const handleChange =
    (key: keyof CategoryType | keyof SubCategoryType) =>
    (value: string | number | null) => {
      setupdatedCategory((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };

  return (
    <div className="modalContent createUser">
      <StyledInputText
        label="Nom*"
        value={updatedCategory.name}
        setValue={handleChange("name")}
      />
      <StyledInputText
        label="Description"
        value={updatedCategory.description || ""}
        setValue={handleChange("description")}
      />
    </div>
  );
};

interface ModalFooterButtonProps {
  onClick?: () => void;
  type: "Créer" | "Modifier";
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  onClick,
  type,
}) => {
  const { loadingStates } = useLoadingStore();
  return (
    <>
      {onClick && (
        <Button
          className="modalFooterButton"
          onClick={onClick}
          label={type}
          type={"submit"}
          isLoading={loadingStates.createCategory}
        />
      )}
    </>
  );
};

export default ModalContent;
export { ModalFooterButton };
