export type CategoryType = {
  category_id: number;
  name: string;
  description: string | null;
  organization_id?: number;
  created_at?: Date;
  updated_at?: Date;
  archived?: boolean;
};

export type SubCategoryType = {
  sub_category_id: number;
  name: string;
  description: string | null;
  organization_id?: number;
  created_at?: Date;
  updated_at?: Date;
  archived?: boolean;
};

export const isCategoryType = (
  category: CategoryType | SubCategoryType,
): category is CategoryType => {
  return (category as CategoryType).category_id !== undefined;
};
